<template>
	<!--todo: put this into a 'header' component-->

	
	<v-app>
		<v-container>
			<v-app-bar>
				<template v-slot:prepend>
					<img class="logo" :src="require('./assets/img/green_E.svg')" />
				</template>

				<v-app-bar-title class="text-start">{{ routeTitle }}</v-app-bar-title>

				<template v-slot:append>
					<!-- <v-btn icon="mdi-magnify"></v-btn>
					<v-badge color="error" content="3" >
						<v-icon icon="mdi-bell" color="primary" ></v-icon>
					</v-badge>
					<v-btn icon="mdi-account-circle" color="secondary"></v-btn> -->
					<div style="margin-right:10px;">{{ authStore.getName }}</div>
					<base-button @click="logout" text="Log Out"></base-button>
				</template>
			</v-app-bar>
		</v-container>
		<v-container style="margin-top: 30px;">
			<router-view />
		</v-container>
	</v-app>
</template>
<script lang="ts">

import { useRoute } from 'vue-router';
import { ref, watchEffect, onMounted, inject } from 'vue';
import { AuthService } from '@/contracts/AuthService';
import { useAuthStore } from '@/stores/auth.store';
import BaseButton from '@/components/BaseButton.vue';

export default {
	name: 'App',
	components: {
		BaseButton
	},
	setup() {
		const route = useRoute();
		const routeTitle = ref<string>('');
		const auth = inject<AuthService>('auth');

		const authStore = useAuthStore();

		const messages = ref<number>(0);

		watchEffect(() => {
			routeTitle.value = String(route.meta.title);
		});

		onMounted(async () => {
			//any logic in here?
			//console.log('mounted', authStore.getName);
		});

		return {
			authStore,
			messages,
			logout: async () => {
				if (auth) {
					auth.logout();
				}
			},
			routeTitle
		}
	}
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
</style>
