<template>
    <div>
        <h1>Workbench Page</h1>
        <!-- Uncomment to test GetGia -->
        <!-- <base-button buttonStyle="moe-button" @click="fetchPropertyInfo(text1, text2)" text="Test Gia" tooltipText="Test Gia Call" :showTooltip="true" tooltipLocation="end" ></base-button>
        <div class="m-4">
            <p>Latitude: {{ text1 }}</p><v-text-field label="Latitude" v-model="text1" variant="solo" width="40px"></v-text-field>
            <p>Longitude: {{ text2 }}</p><v-text-field label="Longitude" variant="solo" v-model="text2" width="40px"></v-text-field>
        </div>
        <div class="pt-4" v-if="giaResult !== null">
            <p>GiaScore: {{ giaResult.giaScore }}</p>
            <p>HailScore: {{ giaResult.hailScore }}</p>
            <p>Messages: {{ giaResult.messages }}</p>
        </div> -->

        <!-- Uncomment to test getDocument -->
         <div class="m-4">
            <v-container>
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Policy" v-model="text1" variant="solo" width="40px"></v-text-field>
                        <base-button buttonStyle="moe-button" @click="importPolicy(text1)" text="Test by Policy" tooltipText="Test DocService" :showTooltip="true" tooltipLocation="end"></base-button>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Address or Lat/Long" variant="solo" v-model="text2" width="40px"></v-text-field>
                        <base-button buttonStyle="moe-button" disabled="true" @click="getLocation(text2)" text="Test by Adress or lat/long" tooltipText="Disabled until needed" :showTooltip="true" tooltipLocation="end"></base-button>
                    </v-col>
                </v-row>
                <v-row justify="center" align="center"  v-if="mapVisible">
                    <v-col cols="12" sm="6" md="4">
                        <GoogleMap
                            api-key="AIzaSyCeDaVPyjqmNXZBlG2H0Uv3UNc41pmgjes"
                            style="width: 100%; height: 400px"
                            :zoom="10"
                            :center="center"
                            :tilt="3" 
                        >
                            <Marker :options="{ position: center }" />
                        </GoogleMap>
                    </v-col>
                </v-row>
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="6" md="4">
                        <div>
                            <p v-if="isWaiting">Waiting...</p>
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="6" md="4">
                        <base-button buttonStyle="moe-button" @click="exportToOnBase()" text="Export to OnBase" tooltipText="Click to send to OnBase" :showTooltip="true" tooltipLocation="end"></base-button>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import { getPropertyInfo, GiaResult } from '@/services/PropertyService';
import { CommercialDataService } from '../services/CommercialDataService';
import { GoogleMap, Marker } from 'vue3-google-map';

export default {
    name: 'ViewWorkbench',
    route: {
        path: '/workbench',
        meta: {
            requiresAuth: true,
            title: 'Workbench'
        }
    },
    data() {
        return {
            giaResult: null,
            text1: '',
            text2: '',
            center: {
                lat: 47.2031139,
                lng: -121.9919582
            },
            mapVisible: false,
            isWaiting: false
        };
    },
    props: {
        // Add any necessary props here
    },
    // Add any necessary data, methods, or lifecycle hooks here
    components: {
        BaseButton,
        GoogleMap,
        Marker
    }, 

    methods: {
        test() {
            alert("Hello! I am an alert box!!");
        },
        async fetchPropertyInfo(lat, long) {
            const propertyInfo = await getPropertyInfo(lat, long);
            this.giaResult = propertyInfo;
        },
        async importPolicy(policyQuery) {
            this.isWaiting = true;
            console.log('Importing policy:', policyQuery);
            try {
                const res = await CommercialDataService.importPolicy(policyQuery);
                this.center = res;
                this.isWaiting = false;
                this.mapVisible = true;
            } catch (error) {
                console.error('Error importing policy:', error);
            }
        },
        async getLocation(address){
            console.log('User entered address: ',address);
            try {
                const location = await CommercialDataService.addLocation(address);
            } catch (error) {
                console.error('Error getting location:', error);
            }
        },
        async exportToOnBase(){
            console.log('Exporting to OnBase');
            try {
                await CommercialDataService.exportToOnBase();
            } catch (error) {
                console.error('Error exporting to OnBase:', error);
            }
        }
    },
}
</script>

<style scoped>
/* Add your custom styles here */
</style>



