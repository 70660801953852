<template>
	<WaitDialog :isActive="isPageLoading" title="Working" />
	<div v-if="errorMessage" class="error-container">
		<div class="error-message">
			{{ errorMessage }}
			<div class="button-container">
				<base-button @click="dismissError" text="OK" />
			</div>
		</div>
	</div>
	<v-overlay v-model="overlayRatingDetails" height="100%" width="100%">
		<v-card class="ma-5">
			<v-card-title>Premium Details
				<div class="float-right">
					<base-button @click="overlayRatingDetails = false" icon="mdi-close" />
				</div>
			</v-card-title>
			<v-container>
				<v-row v-for="(item, index) in requestResponsePairs" :key="index">
					<v-col col="6">
						<base-button @click="copyTextToClipboard('ratingDetailsRequest')" text="Copy to Clipboard" />
						<pre style="overflow: auto; height:85vh; " class="ml-5 mt-5 mb-5">{{ item.request }}</pre>
						<!-- <pre style="overflow: auto; height:85vh; " class="ml-5 mt-5 mb-5">{{ `${item.request} ${index + 1}` }}</pre> -->
					</v-col>
					<v-col col="6">
						<base-button @click="copyTextToClipboard('ratingDetailsResponse')" text="Copy to Clipboard" />
						<pre style="overflow: auto; height:85vh; " class="ml-5 mt-5 mb-5">{{ item.response }}</pre>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-overlay>
	<v-overlay v-model="overlayCoverageSummary" height="100%" width="100%" style="overlay: auto;">
		<v-card class="ma-5">
			<v-card-title>Coverage Summary
				<div class="float-right">
					<base-button @click="exportCoverageSummary" icon="mdi-download-circle"
						buttonStyle="moe-button mr-1" />
					<base-button @click="overlayCoverageSummary = false" icon="mdi-close" />
				</div>
			</v-card-title>
			<v-container>
				<v-data-table :headers="coverageHeaders" :items="coverageSummaryItems"
					class="elevation-1 coverageSummaryTable">
					<template v-slot:[`item.policyPremium`]="{ item }">
						<span :class="{ 'premiumsNotEqual': (item.pointPremium != item.policyPremium) }">{{
		formatCurrency(item.policyPremium) }}</span>
					</template>

					<template v-slot:[`item.pointPremium`]="{ item }">
						<span>{{ formatCurrency(item.pointPremium) }}</span>
					</template>
				</v-data-table>
			</v-container>
		</v-card>
	</v-overlay>
	<v-stepper v-model="selectedStep" v-if="coverageTypes" style="margin: 0 auto;">
		<template v-slot:default="{ }">
			<v-stepper-header>
				<v-stepper-item title="Identify Risks" value="1"></v-stepper-item>
				<v-divider></v-divider>
				<v-stepper-item title="View Premiums" value="2"></v-stepper-item>
			</v-stepper-header>
			<v-stepper-window>
				<v-overlay :model-value="isLoading" class="align-center justify-center" contained>
					<v-progress-circular color="primary" size="64" indeterminate></v-progress-circular>
				</v-overlay>

				<v-stepper-window-item value="1">
					<div class="mx-auto">
						Please select policy criteria <b>OR</b> enter specific quotes / policies below.<br />
						(*) fields are required<br />
					</div>
					<v-form>
						<v-container>
							<v-row>
								<v-col col="5">
									<v-autocomplete @focus="specificQuotesVisibility" @blur="specificQuotesVisibility"
										class="pa-0 ma-0" clearable :items="insuranceLines" item-title="description"
										:item-value="item => item" label="Insurance Line*"
										@update:modelValue="manuallyValidateForm" variant="solo-filled"
										v-model="insuranceLine" :disabled="!!filtersDisabled"></v-autocomplete>
									<v-autocomplete @focus="specificQuotesVisibility" @blur="specificQuotesVisibility"
										class="pa-0 ma-0" clearable :items="states" item-title="name"
										:item-value="item => item" label="Risk State*" variant="solo-filled"
										v-model="riskState" :disabled="!!filtersDisabled"></v-autocomplete>
									<v-text-field @focus="specificQuotesVisibility" @blur="specificQuotesVisibility"
										@change="manuallyValidateForm" class="pa-0 ma-0" clearable
										label="Effective Date*" type="date" variant="solo-filled"
										v-model="effectiveDate" :disabled="!!filtersDisabled"></v-text-field>
									<v-text-field @focus="specificQuotesVisibility" @blur="specificQuotesVisibility"
										@change="manuallyValidateForm" class="pa-0 ma-0" clearable label="End Date"
										type="date" variant="solo-filled" v-model="endDate"
										:disabled="!!filtersDisabled"></v-text-field>
									<v-autocomplete @focus="specificQuotesVisibility" @blur="specificQuotesVisibility"
										chips class="pa-0 ma-0" clearable :items="coverageTypesWithCombinedTitle"
										item-title="combinedTitle" :item-value="item => item"
										label="Specific Coverages(s)" multiple variant="solo-filled"
										v-model="coverageType" :disabled="!!filtersDisabled"></v-autocomplete>
									<v-autocomplete class="pa-0 ma-0" clearable :items="rateSources"
										item-title="displayName" :item-value="item => item" label="Rate Sources*"
										@blur="manuallyValidateForm" multiple variant="solo-filled"
										v-model="rateSource"></v-autocomplete>
								</v-col>
								<v-col cols="1">
									<v-divider vertical color="primary" class="pa-0" style="margin-top: -7px"
										thickness="5"></v-divider>
								</v-col>
								<v-col col="5">
									<v-textarea @focus="filterVisibility"
										@blur="filterVisibility(); validatePolicyNumbers();"
										@change="manuallyValidateForm" class="pa-0 ma-0 resize-none" clearable
										label="Known Quote / Policy Numbers*" rows="14" variant="solo-filled"
										v-model="specificQuotes" :disabled="!!specificQuotesDisabled"></v-textarea>
								</v-col>
							</v-row>
							<v-row justify="center">
								<v-col cols="3">

									<base-button @click="clearFilters" :block="true" text="Clear Filters" />
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-stepper-window-item>

				<v-stepper-window-item value="2">
					<v-container class="container-padding">
						<div class="text-left"><b>Search Criteria</b></div>
						<v-row>
							<v-col cols="3">
								<div class="text-left">Insurance Line: {{ insuranceLine.description }}</div>
							</v-col>
							<v-col cols="3">
								<div class="text-left">Risk State: {{ riskState.name }}</div>
							</v-col>
							<v-col cols="3">
								<div class="text-left">Effective Date: {{ effectiveDateFormatted }}</div>
							</v-col>
							<v-col cols="3" v-if="endDate">
								<div class="text-left">End Date: {{ endDateFormatted }}</div>
							</v-col>
						</v-row>
						<v-row style="margin-top: 0px;">
							<v-col cols="12" style="margin-top: 0px; padding-top: 0px">
								<div class="text-left">Coverages:</div>
								<v-list style="padding-bottom: 0px; padding-top: 0px" density="compact">
									<v-list-item style="padding-bottom: 0px; padding-top: 0px; min-height: 20px"
										v-for="ct in coverageType" :key="ct.coverageTypeCode">
										<v-list-item-title class="text-left">{{ ct.coverageTypeCode }} [{{
		ct.insuranceLineCode
	}}] - {{ ct.coverageTypeDescription }}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-col>
						</v-row>
					</v-container>
					<v-divider thickness="5px"></v-divider>
					<v-data-iterator :items="policies" :items-per-page="policyItemsPerPage" :page="policyCurrentPage">
						<template v-slot:header="">
							<v-row>
								<v-col v-for="(header, index) in policyTableHeader" :key="index" class="header-title">
									{{ header.title }}
								</v-col>
							</v-row>
							<v-divider thickness="3px"></v-divider>
						</template>
						<template v-slot:default="{ items }">
							<template v-for="(item, i) in items" :key="i">
								<v-row class="d-flex" align="center">
									<v-col v-for="(header, index) in policyTableHeader" :key="index"
										:class="[header.align]">
										<template v-if="item.raw[header.key] === undefined">
											<v-progress-linear color="primary" height="6" indeterminate rounded>
											</v-progress-linear>
										</template>
										<template v-else>
											<template
												v-if="['RsxDraft', 'RsxApproval', 'RsxReleased'].includes(header.key)">
												<v-btn :text="item.raw[header.key]"
													:class="getRatingResultElementClass(item, header.key)"
													variant="plain">
													{{ item.raw[header.key] }}
													<v-menu offset-y activator="parent" open-on-hover>
														<v-list>
															<v-list-item link
																@click="showCoverageSummary(item.raw[header.key + 'DocumentId'])">
																<v-list-item-title><v-icon
																		icon="mdi-list-status"></v-icon> Coverage
																	Summary</v-list-item-title>
															</v-list-item>
															<v-list-item link
																@click="showRatingDetails(item.raw[header.key + 'DocumentId'])">
																<v-list-item-title><v-icon
																		icon="mdi-code-json"></v-icon> View
																	Json</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-menu>
												</v-btn>
											</template>
											<template v-else>
												{{ item.raw[header.key] }}
											</template>
										</template>
									</v-col>
								</v-row>
								<v-divider class="m-0" thickness="1px"></v-divider>
							</template>
						</template>
						<template v-slot:footer="">
							<v-row class="justify-center footer-padding">
								<v-col cols="2">
									<v-select label="Items per page" :items="[20, 50, 100]"
										v-model="policyItemsPerPage">
									</v-select>
								</v-col>
								<v-col cols="6">
									<v-pagination v-model="policyCurrentPage" :length="totalMatchingPolicies"
										:total-visible="10">
									</v-pagination>
								</v-col>
								<v-col cols="2">
									Total Policies: {{ totalFoundPolicies }}
								</v-col>
								<v-col cols="2">
									<base-button @click="exportSearchResults" prepend-icon="mdi-download-circle"
										text="Export" />
								</v-col>
							</v-row>
						</template>
					</v-data-iterator>
				</v-stepper-window-item>
			</v-stepper-window>
			<v-stepper-actions :disabled="disablePrevNext" @click:prev="prevStep" @click:next="nextStep"
				:prev-text="prevButtonName">
			</v-stepper-actions>
		</template>
	</v-stepper>
</template>

<script lang="ts">

import { computed, inject, onMounted, ref, watch } from 'vue';
import { Tell } from '@/services/Tell';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EntraTokenCredentials } from '@/services/EntraTokenCredentials';
import { EntraAuthService } from '@/services/EntraAuthService';
import { RateService } from '@/contracts/RateService';
import { v4 as uuidv4 } from 'uuid';
import { utils, writeFile } from 'xlsx';

import WaitDialog from '@/components/WaitDialog.vue';

import * as models from '@/models/RateOMaticViewModels';
import moment from 'moment';

import insuranceLinesJson from '../json/insuranceLine.json';
import policyTableHeaderJson from '../json/policyTableHeader.json';
import statesJson from '../json/states.json';
import rateSourcesJson from '../json/rateSources.json';
import BaseButton from '@/components/BaseButton.vue';
import { OrderByParam, PolicySearchRequest } from '@/models/PolicyModels';
import * as ratingModels from '@/models/RatingModels';
import { PolicyService } from '@/contracts/PolicyService';

export default {
	name: 'RateOMatic',
	route: {
		path: '/rateomatic',
		meta: {
			requiresAuth: true,
			title: 'Rate-O-Matic™'
		}
	},
	components: {
		WaitDialog,
		BaseButton
	},
	setup() {
		const telemetry = inject('telemetry') as ApplicationInsights;
		const tell = inject('tell') as Tell;
		const entraAuth = inject('auth') as EntraAuthService;
		const rateService = inject('rateService') as RateService;
		const policyService = inject('policyService') as PolicyService;

		const areQuotesValid = ref<boolean>(true);
		const correlationId = ref<string>(null);
		const coverageType = ref<models.CoverageType[]>(null);
		const coverageTypes = ref<models.CoverageType[]>(null);
		const coverageTypesJson = ref<models.CoverageType[]>(null);
		const coverageSummaryItems = ref<ratingModels.CoverageSummaryItem[]>([]);
		const credentials = ref<EntraTokenCredentials>(null);
		const effectiveDate = ref<any>(null);
		const effectiveDateFormatted = ref<any>(null);
		const endDate = ref<any>(null);
		const endDateFormatted = ref<any>(null);
		const errorMessage = ref<string | null>(null);
		const filtersDisabled = ref<boolean>(false);
		const insuranceLine = ref<any>([]);
		const insuranceLines = ref<any[]>([]);
		const isExporting = ref<boolean>(false);
		const isLoading = ref<boolean>(false);
		const isFormValid = ref<boolean>(false);
		const isPageLoading = ref<boolean>(false);
		const isPaging = ref<boolean>(false);
		const overlayRatingDetails = ref<boolean>(false);
		const overlayCoverageSummary = ref<boolean>(false);
		const policies = ref<any[]>([]);
		const policyCurrentPage = ref<number>(1);
		const policyItemsPerPage = ref<number>(20);
		const policySort = ref<any[]>([]);
		const policyTableHeader = ref<any[]>(null);
		const prevButtonName = ref<string>('');
		const rateSource = ref<any[]>(null);
		const rateSources = ref<any[]>([]);
		const rateSourcesFromJson = ref<any[]>([]);
		const ratingDetailsRequest = ref<any[]>([]);
		const ratingDetailsResponse = ref<any[]>([]);
		const requestResponsePairs = ref<ratingModels.RequestResponsePairs[]>([]);
		const riskState = ref<any>("");
		const selectedPolicies = ref<any[]>([]);
		const selectedStep = ref<number>(0);
		const showActions = ref<boolean>(false);
		const specificQuotes = ref<string>(null);
		const specificQuotesDisabled = ref<boolean>(false);
		const states = ref<any[]>([]);
		const steps = ref<number>(1);
		const totalFoundPolicies = ref<number>(0);
		const totalMatchingPolicies = ref<number>(0);
		const userName = ref<string>(null);

		const coverageTypesWithCombinedTitle = computed(() => {
			return coverageTypes.value.map((item: models.CoverageType) => ({
				...item,
				combinedTitle: `${item.coverageTypeCode} [${item.insuranceLineCode}] - ${item.coverageTypeDescription}`
			}));
		});

		const disablePrevNext = computed(() => {
			if (!isFormValid.value) {
				return true;
			}

			if (selectedStep.value === 1) {
				return (!selectedPolicies.value || selectedPolicies.value.length === 0) ? 'next' : undefined;
			}

			return selectedStep.value === 0 ? 'prev' : selectedStep.value === steps.value ? 'next' : undefined;
		});

		const copyTextToClipboard = async function (id: string) {
			try {
				if (id === "ratingDetailsRequest")
					await navigator.clipboard.writeText(JSON.stringify(ratingDetailsRequest.value, null, 2));
				else if (id === "ratingDetailsResponse")
					await navigator.clipboard.writeText(JSON.stringify(ratingDetailsResponse.value, null, 2));
			} catch (error) {
				errorMessage.value = "Failed to copy text.";
				telemetry.trackException(error);
			}
		}

		const showRatingDetails = async function (documentId: string) {
			try {
				isPageLoading.value = true;

				let result = await rateService.getRatingDetails(documentId);

				ratingDetailsRequest.value = [];
				ratingDetailsResponse.value = [];

				requestResponsePairs.value = result.ratingDetails.requestResponsePairs;

				result.ratingDetails.requestResponsePairs.forEach(detail => {
					ratingDetailsRequest.value.push(detail.request);
					ratingDetailsResponse.value.push(detail.response);
				});

				//turn on the overlay
				overlayRatingDetails.value = true;
			} catch (error) {
				errorMessage.value = "An error occurred while loading Rating Details.";
				telemetry.trackException(error);
				overlayRatingDetails.value = false;
			} finally {
				isPageLoading.value = false;
			}
		}

		const showCoverageSummary = async function (documentId: string) {
			try {
				isPageLoading.value = true;

				let result = await rateService.getCoverageSummary(documentId);

				coverageSummaryItems.value = result;

				//turn on the overlay
				overlayCoverageSummary.value = true;
			} catch (error) {
				errorMessage.value = "An error occurred while loading Rating Details.";
				telemetry.trackException(error);
				overlayCoverageSummary.value = false;
			} finally {
				isPageLoading.value = false;
			}
		}

		const dismissError = () => {
			errorMessage.value = null;
		};

		const filterCoverageTypes = () => {
			coverageTypes.value = coverageTypesJson.value
				.filter(ct => ((insuranceLine.value == null || insuranceLine.value.insLineCode == null) || ct.insuranceLineCode == insuranceLine.value.insLineCode))
		}

		const loadCoverageTypes = async function () {
			isPageLoading.value = true;

			try {
				const response = await fetch(`${process.env.VUE_APP_API_ROOT_URL}/api/meta/coveragetypes`);
				let responseJson = await response.json();

				if (response.ok) {
					coverageTypesJson.value = responseJson
						.sort((a: models.CoverageType, b: models.CoverageType) => a.coverageTypeCode < b.coverageTypeCode ? -1 : a.coverageTypeCode > b.coverageTypeCode ? 1 : 0);
					filterCoverageTypes();
				} else {
					telemetry.trackException(responseJson.message);
				}

			} catch (error) {
				errorMessage.value = "An error occurred while loading Coverage Types.";
				telemetry.trackException(error);
			} finally {
				isPageLoading.value = false;
			}

			isPageLoading.value = false;
		}

		const exportSearchResults = async function () {
			isExporting.value = true;

			// ToDo: This is a workaround. We need to refactor this to be more efficient.
			let tempPolicyItemsPerPage = policyItemsPerPage.value;

			await searchForPolicies({ page: 1, itemsPerPage: totalFoundPolicies.value, sortBy: policySort.value });

			// Reset the items per page to the original value
			policyItemsPerPage.value = tempPolicyItemsPerPage;

			const worksheet = utils.json_to_sheet(policies.value);
			const workbook = utils.book_new();
			utils.book_append_sheet(workbook, worksheet, "Sheet1");
			writeFile(workbook, "data.xlsx");

			isExporting.value = false;
		}

		const exportCoverageSummary = async function () {
			isExporting.value = true;

			const worksheet = utils.json_to_sheet(coverageSummaryItems.value);
			const workbook = utils.book_new();

			utils.book_append_sheet(workbook, worksheet, "CoverageSummary");

			writeFile(workbook, "Coverage Summary.xlsx");

			isExporting.value = false;
		}

		const searchForPolicies = async ({ page, itemsPerPage, sortBy }: models.SearchParams) => {
			if (isPaging.value === true) {
				return;
			}

			selectedPolicies.value = [];
			isPaging.value = true;
			policyCurrentPage.value = page;
			policyItemsPerPage.value = itemsPerPage;
			policySort.value = sortBy;
			let sortByParams: OrderByParam[] = [];

			if (sortBy == null || sortBy.length == 0) {
				sortByParams = [
					{
						order: "RiskState",
						direction: "Ascending"
					},
					{
						order: "PolicyNumber",
						direction: "Ascending"
					}
				];
			} else {
				sortByParams = sortBy.map(s => ({
					order: s.key,
					direction: s.order == 'asc' ? 'Ascending' : 'Descending'
				}));
			}

			var searchRequest = new PolicySearchRequest({
				scope: "pending",
				lineOfBusinessCodes: [] as string[],
				insuranceLineCodes: [] as string[],
				riskStates: [] as string[],
				coverageTypeCodes: [] as string[],
				policyNumbers: [] as string[],
				effectiveDate: {
					start: "",
					end: ""
				},
				page: page,
				pageSize: itemsPerPage,
				orderBy: sortByParams
			});

			// if there are specific quote/policy numbers then that is the only criteria that needs to be sent to the API
			if (specificQuotes.value != null && specificQuotes.value.length > 0) {
				searchRequest.policyNumbers = specificQuotes.value.split('\n');
			}
			else {
				if (insuranceLine.value != null && insuranceLine.value.insLineCode != null) {
					searchRequest.insuranceLineCodes.push(insuranceLine.value?.insLineCode);
				}

				if (riskState.value != null) {
					searchRequest.riskStates.push(riskState.value.abbreviation);
				}

				if (effectiveDate.value != null) {
					searchRequest.effectiveDate.start = effectiveDate.value;
					effectiveDateFormatted.value = moment(effectiveDate.value).format("MM/DD/YYYY");
				}

				if (endDate.value != null) {
					searchRequest.effectiveDate.end = endDate.value;
					endDateFormatted.value = moment(endDate.value).format("MM/DD/YYYY");
				}

				if (coverageType.value != null) {
					searchRequest.coverageTypeCodes = coverageType.value.map(ct => ct.coverageTypeCode);
				}
			}

			try {
				let response = await policyService.searchPolicies(searchRequest);

				//create some models for binding that use this result
				policies.value = response.policies;
				totalMatchingPolicies.value = Math.ceil(response.totalMatchingPolicies / policyItemsPerPage.value);
				totalFoundPolicies.value = response.totalMatchingPolicies;

				policies.value.forEach(policy => {
					policy.effectiveDate = moment(policy.effectiveDate, "YYYY/MM/DD").format("MM/DD/YYYY")
					policy.expirationDate = moment(policy.expirationDate, "YYYY/MM/DD").format("MM/DD/YYYY")
				});

				if (isExporting.value == true) {
					await requestRates();
				} else {
					requestRates();
				}
			} catch (error) {
				errorMessage.value = "An error occurred while searching for Policies.";
				telemetry.trackException(error);
			} finally {
				isPaging.value = false;
			}

			isPaging.value = false;
		}

		const getRatingResultElementClass = (policyLineItem: any, columnHeaderKey: string) => {
			let elementClass = "overlayButton";
			const pointPremium = policyLineItem.raw.totalPremium;
			const rsxPremium = policyLineItem.raw[`${columnHeaderKey}Premium`];

			if (pointPremium && (!rsxPremium || pointPremium != rsxPremium)) {
				elementClass += " premiumsNotEqual"
			}

			return elementClass;
		}

		const formatCurrency = (amount: number) => {
			// Format the number with commas
			const formatter = new Intl.NumberFormat('en-US');
			let amountStr = formatter.format(amount);

			// Calculate the padding size
			const maxDigits = 7; // 999,999 has 6 digits, and a comma
			const paddingSize = maxDigits - amountStr.length;

			// Pad the string with leading spaces
			amountStr = amountStr.padStart(paddingSize + amountStr.length, ' ');

			return `$${amountStr}`;
		}

		const requestRates = async function () {
			var currentPolicyNumbers = [];

			if (specificQuotes.value != null && specificQuotes.value.length > 0) {
				currentPolicyNumbers = specificQuotes.value.split('\n');
			} else {
				currentPolicyNumbers = policies.value.map(sp => sp.policyNumber);
			}

			rateSource.value.unshift(rateSourcesFromJson.value.filter(rs => rs.key === "Point")[0]);

			try {
				for (var rs = 0; rs < rateSource.value.length; rs++) {			// loop over rate sources
					for (var pn = 0; pn < currentPolicyNumbers.length; pn++) {	// loop over policy numbers
						let rateRequest = new ratingModels.RateRequest();
						rateRequest.policyNumber = currentPolicyNumbers[pn];
						rateRequest.ratingEnvironment = rateSource.value[rs].key;
						rateRequest.correlationId = correlationId.value;
						rateRequest.contextInfo.userId = userName.value;
						rateRequest.requestSource = "ROM";

						let policy = policies.value.find(policy => policy.policyNumber === rateRequest.policyNumber.toUpperCase().trim());

						if (rateSource.value[rs].key === "Point") {
							if (policy) {
								if (!policy.totalPremium || policy.totalPremium == null) {
									policy.totalPremium = 0;
								}

								policy[rateRequest.ratingEnvironment] = (policy.totalPremium != null) ? formatCurrency(policy.totalPremium) : 0;
							}

							continue;
						}

						// ToDo: This is duplicated code and should be refactored. But for now it is working.
						// This also would be cleaner after we have a rate many endpoint.
						if (isExporting.value == true) {
							await rateService.ratePolicy(rateRequest)
								.then(async response => {
									if (response.ok) {
										let data = await response.json();
										let totalPremium = formatCurrency(data.totalPremium);
										let documentId = data.documentId;

										if (policy && rateRequest && data) {
											policy[rateRequest.ratingEnvironment] = totalPremium;
											policy[rateRequest.ratingEnvironment + "DocumentId"] = documentId;
											policy[rateRequest.ratingEnvironment + "Premium"] = data.totalPremium;
										}
									} else {
										tell.error("An error occurred while requesting rates.");
										telemetry.trackException(await response.json());
									}
								})
								.catch(error => {
									errorMessage.value = "An error occurred while requesting rates.";
									telemetry.trackException(error);
								});
						} else {
							rateService.ratePolicy(rateRequest)
								.then(async response => {
									if (response.ok) {
										let data = await response.json();
										let totalPremium = formatCurrency(data.totalPremium);
										let documentId = data.documentId;

										if (policy && rateRequest && data) {
											policy[rateRequest.ratingEnvironment] = totalPremium;
											policy[rateRequest.ratingEnvironment + "DocumentId"] = documentId;
											policy[rateRequest.ratingEnvironment + "Premium"] = data.totalPremium;
										}
									} else {
										tell.error("An error occurred while requesting rates.");
										telemetry.trackException(await response.json());
									}
								})
								.catch(error => {
									errorMessage.value = "An error occurred while requesting rates.";
									telemetry.trackException(error);
								});
						}
					}
				}
			} catch (error) {
				errorMessage.value = "An error occurred while requesting rates.";
				telemetry.trackException(error);
			}
		}

		const rateRiskStepLogic = async function () {
			await searchForPolicies({ page: policyCurrentPage.value, itemsPerPage: policyItemsPerPage.value, sortBy: policySort.value });

			// ToDo: This has to move in its own function and be called from the searchForPolicies function.
			rateSource.value.forEach(source => {
				policyTableHeader.value.push({
					"title": source.displayName,
					"align": "text-end",
					"key": source.key
				});
			});
		}

		const stepSwitch = () => {
			switch (selectedStep.value) {
				case 0:
					clearFilters();
					prevButtonName.value = '';
					break;
				case 1:
					prevButtonName.value = 'New search';
					rateRiskStepLogic();
					break;
			}
		}

		// ToDo: This might not be needed. Remove after step 3 got implemented.
		const prevStep = () => {
			if (selectedStep.value > 0) {
				selectedStep.value -= 1;
			}

			stepSwitch();
		}

		// ToDo: This might not be needed. Remove after step 3 got implemented.
		const nextStep = () => {
			if (selectedStep.value <= steps.value) {
				selectedStep.value += 1;
			}

			stepSwitch();
		}

		const specificQuotesVisibility = () => {
			specificQuotesDisabled.value = (insuranceLine.value == null || insuranceLine.value == "") ? false : true;
			if (specificQuotesDisabled.value === true) return;

			specificQuotesDisabled.value = (riskState.value == null || riskState.value == "") ? false : true;
			if (specificQuotesDisabled.value === true) return;

			specificQuotesDisabled.value = (effectiveDate.value == null || effectiveDate.value == "") ? false : true;
			if (specificQuotesDisabled.value === true) return;

			specificQuotesDisabled.value = (coverageType.value == null || coverageType.value.length == 0) ? false : true;
			if (specificQuotesDisabled.value === true) return;
		}

		const validatePolicyNumbers = () => {
			const regex = /^[a-zA-Z]{3}\d{9}$/;

			areQuotesValid.value = true;

			if (specificQuotes.value == null || specificQuotes.value.length == 0) {
				return false;
			}

			let quotes = specificQuotes.value.split('\n');
			for (let i = 0; i < quotes.length; i++) {
				if (quotes[i] != '' && !regex.test(quotes[i])) {
					tell.error(`${quotes[i]} is invalid, please correct or remove from search criteria.`);
					areQuotesValid.value = false;
					break;
				}
			}
		};

		const filterVisibility = () => {
			filtersDisabled.value = (specificQuotes.value == null || specificQuotes.value.length == 0) ? false : true;
		}

		const manuallyValidateForm = () => {
			isFormValid.value = false;

			if (
				(specificQuotes.value != null && specificQuotes.value.length > 0 && areQuotesValid.value && rateSource.value != null && rateSource.value.length > 0) ||
				(insuranceLine.value != null && riskState.value != null && effectiveDate.value != null && rateSource.value != null && rateSource.value.length > 0)
			) {
				isFormValid.value = true;
			}
		}

		const clearFilters = () => {
			areQuotesValid.value = false
			insuranceLine.value = []
			riskState.value = ""
			effectiveDate.value = null
			effectiveDateFormatted.value = null
			endDate.value = null
			endDateFormatted.value = null
			coverageType.value = null
			specificQuotes.value = null
			isFormValid.value = false
			coverageTypes.value = coverageTypesJson.value
			filtersDisabled.value = false
			specificQuotesDisabled.value = false
			rateSource.value = []
			selectedPolicies.value = []
			policies.value = []
			policyTableHeader.value = JSON.parse(JSON.stringify(policyTableHeaderJson)); // deep copy
			policyCurrentPage.value = 1
			correlationId.value = uuidv4();
		}

		onMounted(async () => {
			const instance = await entraAuth.getInstance();
			const account = await entraAuth.getActiveAccount();
			const credential = new EntraTokenCredentials(instance, account);
			correlationId.value = uuidv4();

			userName.value = account.username;

			await loadCoverageTypes();

			states.value = statesJson;
			policyTableHeader.value = JSON.parse(JSON.stringify(policyTableHeaderJson)); //deep copy

			insuranceLines.value = insuranceLinesJson
				.sort((a, b) => a.description < b.description ? -1 : a.description > b.description ? 1 : 0);

			rateSourcesFromJson.value = JSON.parse(JSON.stringify(rateSourcesJson));
			rateSources.value = JSON.parse(JSON.stringify(rateSourcesFromJson.value)); // deep copy

			// Remove Point option from dropdown. We display Point rates by default.
			rateSources.value = rateSources.value.filter(rs => rs.key !== "Point");

			isFormValid.value = false;

			// speeding up testing
			// insuranceLine.value = insuranceLines.value.find(il => il.insLineCode === "UMC");
			// riskState.value = states.value.find(s => s.abbreviation === "AZ");
			// effectiveDate.value = "2024-07-08";
			// specificQuotes.value = "BPQ050017500";
			// rateSource.value = rateSources.value.filter(rs => rs.key === "RsxDraft");
			// isFormValid.value = true;
		});

		watch(insuranceLine, (newValue, oldValue) => {
			if (newValue !== oldValue) {
				coverageType.value = null;
				filterCoverageTypes();
			}
		});

		watch(policyCurrentPage, (newValue, oldValue) => {
			if (newValue !== oldValue) {
				searchForPolicies({ page: newValue, itemsPerPage: policyItemsPerPage.value, sortBy: policySort.value });
			}
		});

		watch(policyItemsPerPage, (newValue, oldValue) => {
			if (newValue !== oldValue) {
				searchForPolicies({ page: policyCurrentPage.value, itemsPerPage: newValue, sortBy: policySort.value });
			}
		});

		return {
			areQuotesValid,
			clearFilters,
			copyTextToClipboard,
			correlationId,
			coverageType,
			coverageTypes,
			coverageTypesJson,
			coverageTypesWithCombinedTitle,
			coverageSummaryItems,
			credentials,
			disablePrevNext,
			dismissError,
			effectiveDate,
			effectiveDateFormatted,
			endDate,
			endDateFormatted,
			errorMessage,
			exportSearchResults,
			exportCoverageSummary,
			filterCoverageTypes,
			filtersDisabled,
			filterVisibility,
			getRatingResultElementClass,
			formatCurrency,
			insuranceLine,
			insuranceLines,
			isExporting,
			isFormValid,
			isLoading,
			isPageLoading,
			isPaging,
			manuallyValidateForm,
			nextStep,
			overlayCoverageSummary,
			overlayRatingDetails,
			policies,
			policyCurrentPage,
			policyItemsPerPage,
			policySort,
			policyTableHeader,
			prevButtonName,
			prevStep,
			rateRiskStepLogic,
			rateSource,
			rateSources,
			rateSourcesFromJson,
			ratingDetailsRequest,
			ratingDetailsResponse,
			requestResponsePairs,
			requestRates,
			riskState,
			searchForPolicies,
			selectedPolicies,
			selectedStep,
			showActions,
			showCoverageSummary,
			showRatingDetails,
			specificQuotes,
			specificQuotesDisabled,
			specificQuotesVisibility,
			states,
			stepSwitch,
			steps,
			totalFoundPolicies,
			totalMatchingPolicies,
			userName,
			validatePolicyNumbers,
			coverageHeaders: [
				//{ text: 'Insurance Line', value: 'insuranceLine' },
				{ title: 'Coverage Type', key: 'coverageType' },
				{ title: 'Point Premium', key: 'pointPremium', align: 'end' },
				{ title: 'Premium', key: 'policyPremium', align: 'end' },
				{ title: 'Final Rate', key: 'finalRate', align: 'end' },
				{ title: 'Location', key: 'location', align: 'end' },
				{ title: 'Sub Loc.', key: 'subLocation', align: 'end' },
				{ title: 'Unit', key: 'unit', align: 'end' },
				{ title: 'Seq.', key: 'sequence', align: 'end' },
			],
		}
	},
}
</script>

<style scoped lang="scss">
.container-padding {
	padding-top: 0px;
	padding-bottom: 0px;
}

.footer-padding {
	padding-top: 20px;
}

.header-title {
	color: var(--v-primary-base);
	font-size: 16px;
	font-weight: bold;
}

.overlayButton {
	text-decoration: underline;
}

.overlayButton:hover {
	transform: scale(1.2);
}

.premiumsNotEqual {
	color: var(--bs-red);
	font-weight: bold;
}

.v-stepper-window {
	margin-bottom: 0px;
}

:deep(.coverageSummaryTable) {

	ol,
	ul,
	dl {
		margin-bottom: 0px;
	}

	.v-data-table-footer__items-per-page {
		display: none;
	}
}
</style>
