<template>
	<WaitDialog :isActive="isWaiting" title="Fixing policy..." />
	
	<PolicySummary :policyNumber="policyNumber" />

	<div class="container text-start">
		<p>This function will correct some known issues with policy data.</p>
		<p>This feature is only to be used if this policy module is missing data and looks like this:

			<img src="../assets/img/brokenpolicy.jpg" alt="Broken Policy" />
		</p>
		<p>Please note, once you take this action, <strong>you must delete this pending module.</strong></p>
		<p>Once you delete this module, you may create a new renewal module from the existing term.</p>
	</div>
	<div class="text-center pa-4">
		<v-dialog v-model="dialog" max-width="400" persistent>
			<template v-slot:activator="{ props: activatorProps }">
				<v-btn v-bind="activatorProps" class="moe-button">
					Fix my policy
				</v-btn>
			</template>

			<v-card prepend-icon="mdi-map-marker"
				text="Once you take this action, you will be required to delete your pending module."
				title="Are you sure?">
				<template v-slot:actions>
					<v-spacer></v-spacer>

					<base-button @click="dialog = false" text="Nevermind" />

					<base-button @click="fixMyPolicy" text="Let's go!" />
				</template>
			</v-card>
		</v-dialog>
	</div>
</template>

<script lang="ts">
import { ref, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import PolicySummary from '@/components/PolicySummary.vue';
import WaitDialog from '@/components/WaitDialog.vue';
import { Tell } from '@/services/Tell';
import { ApplicationInsights, SeverityLevel, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import BaseButton from '@/components/BaseButton.vue';

export default {
	//name doesn't match the file name, but we're doing it like this for uri simplicity on Entra config
	//and for clarity on the vue app
	name: 'PolicyFix',
	route: {
		path: '/policy-fix',
		meta: {
			requiresAuth: true,
			title: 'Policy Fix'
		}
	},
	components: {
		PolicySummary,
		WaitDialog,
		BaseButton
	},
	props: {
		//these properties are exposed to consumers of this component
	},
	computed: {

	},

	setup() {
		const route = useRoute();

		const policyNumber = ref(String(route.query.policyNumber));
		const isWaiting = ref(false);
		const dialog = ref(false);

		const tell = inject('tell') as Tell;
		const telemetry = inject('telemetry') as ApplicationInsights;

		const fixMyPolicy = async () => {
			dialog.value = false;
			isWaiting.value = true;

			//TODO: this should be a POST and not a GET
			const response = await fetch(`${process.env.VUE_APP_API_ROOT_URL}/api/policy/fix/${policyNumber.value}`);

			//TODO: is there a way to hook into 'fetch' and handle this globally?
			isWaiting.value = false;

			if (response.ok) {
				// Handle success, pass 0 so that the user has to dismiss the message
				tell.success('Successfully updated.', 0);
			}
			else {
				//track this exception, and also the event
				let exceptionTelemetry: IExceptionTelemetry = {
					exception: new Error("Failed to update policy"),
					severityLevel: SeverityLevel.Error,
					properties: { policyNumber: policyNumber.value }
				};

				telemetry.trackException(exceptionTelemetry);

				//pass 0 here to let user know that it didn't work
				tell.error("There was an error updating the policy. Please reach out to Help Desk.", 0);
			}

			//track that this event happened
			telemetry.trackEvent({ name: `PolicyFix.${ response.ok ? "Success" : "Failed" }`, properties: { policyNumber: policyNumber.value } });
		}

		onMounted(async () => {
			//anything to do here?
		});

		return {
			isWaiting,
			dialog,
			policyNumber,
			fixMyPolicy
			//this is the data that is local to this component and reactive
			//use the 'reactive' keyword to make objects and arrays reactive
			//use the 'ref' keyword for simple types
		}
	}
}
</script>
<style scoped></style>
