<template>

	<WaitDialog :isActive="isWaiting" title="Working" />

	<v-navigation-drawer permanent v-model="drawer" :rail="rail" elevation="5" color="#d67c3b" @click="rail = false" width="320">

		<v-list density="compact" class="mt-3" nav>
			<v-list-item class="text-start" href="https://enumclawpandc.sharepoint.com/:b:/r/sites/FaCTTeam/Shared%20Documents/General/Homer%20Migrated%20Content/UW%20Workbench/UW%20Workbench%20User%20Guide.pdf?csf=1&web=1&e=wWyGUP" prepend-icon="mdi-book-open-variant" title="User Guide"
				value="guide"></v-list-item>
			<v-list-item @click="sendDocument()" class="text-start custom-list-item" prepend-icon="mdi-send"
				title="Send to OnBase" value="onbase"></v-list-item>
			<v-list-item class="text-start" target="_blank"  :href=plrReportUrlPolicy prepend-icon="mdi mdi-chart-line" title="Policy Loss Ratio Report"
				value="report"></v-list-item>
			<v-list-item class="text-start" target="_blank"  :href=electronicWorksheetURLValue prepend-icon="mdi-file-document" title="Most Recent Electronic Worksheet"
				value="worksheet"></v-list-item>
		</v-list>

		<template v-slot:append>
			<v-container>
				<v-row justify="end" v-if="!rail">
					<v-btn icon="mdi-chevron-left" variant="text" @click.stop="rail = !rail"></v-btn>
				</v-row>

				<v-row justify="center">
					<v-btn icon="mdi-chevron-right" variant="text" @click.stop="rail = !rail" v-if="rail"></v-btn>
				</v-row>
			</v-container>
		</template>
	</v-navigation-drawer>

	<v-container >
		<div id="search-box" rounded="xl" class="mt-10 h-auto rounded-lg elevation-13"
			style="color: var(--primary-color)">
			<v-img style="height: 80px !important;" :src="require('../assets/img/moe-city.png')" cover
				class="h-auto rounded-t-lg"></v-img>
			<!-- <div style="height: 15%;"></div> -->
			<v-row class="align-end justify-end">
				<v-col cols="12" sm="12" md="6" lg="6">
					<v-row class="justify-center xs-mt-8 pt-sm-8 py-md-8">
						<v-col cols="10" sm="10" md="8" lg="8">
							<v-text-field id="policyInput" text="text" hide-details="true"
								label="Policy / renewal quote number and module (FPP123456700)" v-model="text1" variant="solo"
								width="40px" density="comfortable"></v-text-field>
						</v-col>
						<v-col cols="10" sm="10" md="2" lg="2">
							<base-button height="48px" width="120px" buttonStyle="moe-button-secondary"
								@click="testPolicy(text1)" text="Import"></base-button>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12" sm="12" md="6" lg="6">
					<v-row class="justify-center xs-mb-8 pb-sm-8 py-md-8">
						<v-col cols="10" sm="10" md="8">
							<v-text-field id="addressInput" hide-details="true" label="Enter an address or latitude, longitude"
								variant="solo" v-model="text2" width="40px" density="comfortable"></v-text-field>
						</v-col>
						<v-col cols="10" sm="10" md="2">
							<base-button height="48px" width="120px" buttonStyle="moe-button-secondary"
								@click="getLocation(text2)" text="Add"></base-button>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</div>

		<div id="billingAccepted" class="mb-10 mt-12 text-left d-flex flex-row" v-if="goodPolicyEvaluation">
			<p class="pa-4 mb-0">BillingCenter Policy Evaluation: </p> 
			<p :class="{'pa-4 mb-0 text-red': policyEvaluation !== 'Acceptable', 'pa-4 mb-0 text-green': policyEvaluation === 'Acceptable'}">
				{{ policyEvaluation }}
			</p>
		</div>

		<div v-for="(location, index) in resultsInput " :key="index"
			id="results-box" class="mt-10 h-auto rounded-t-lg elevation-13" style="width: 100% !important; color: var(--secondary-color);">
			<v-table>
				<thead>
					<tr id="title-row">
						<th style="width: 35%;">Address Location: </th>
						<th style="width: 25%;">
							Total Insured Value:</th>
						<th style="width: 15%;" class="text-center">Fire Score</th>
						<th style="width: 15%;" class="text-center">Hail Score</th>
						<th style="width: 10%;" class="text-end">
							<base-button @click="removeLocation(index)" size="small" buttonStyle="moe-button-secondary" text="Remove"></base-button>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr id="sub-content">
						<td style="width: 35%;" class="text-start">{{ location.address }}</td>
						<td style="width: 25%;" class="text-start">
							<v-row no-gutters v-for="(insLine, insIndex) in location.insLineTIVs" :key="insIndex">
								<v-col class="justify-start py-0 pl-0">
									<p class="my-2">{{ insLine.insLine }}:</p>
								</v-col>
								<v-col class="justify-end py-0 pl-0">
									<p class="my-2">{{ formatCurrency(insLine.tiv) }}</p>
								</v-col>
							</v-row>
						</td>
						<td style="width: 15%;" class="text-center">{{ location.fireScore }}</td>
						<td style="width: 15%;" class="text-center">{{ location.hailScore }}</td>
						<td style="width: 10%;" class="text-end">
						</td>
					</tr>
				</tbody>
			</v-table>
			<GoogleMap ref="testMap" api-key="AIzaSyCeDaVPyjqmNXZBlG2H0Uv3UNc41pmgjes" style="width: 100%; height: 400px"
				:center="location.location" :zoom="19" :tilt="45" :map-type-id="'hybrid'">
				<Marker :options="{ position: center }" />
			</GoogleMap>
			<!-- <div id="map" class="h-100 w-100"></div> -->
			<div class="footer">
				<v-row class="pa-4 justify-space-between align-center rounded-b-lg">
					<v-col cols="2">
						<p class="text-center m-0">Location notes:</p>
					</v-col>
					<v-col cols="10">
						<v-textarea clearable hide-details="true" :auto-grow=true class="mx-2" rows="1" label="Comments"
							variant="solo" placeholder="Enter any comments on location"></v-textarea>
					</v-col>
				</v-row>
			</div>
		</div>

	</v-container>

</template>

<script lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import { getPropertyInfo, GiaResult } from '@/services/PropertyService';
import { CommercialDataService, electronicWorksheetURL } from '../services/CommercialDataService';
import { GoogleMap, Marker } from 'vue3-google-map';
import { ResultsInput } from '@/models/CommercialDataModel';
import { onMounted, ref } from 'vue';
import WaitDialog from '@/components/WaitDialog.vue';

export default {
	name: 'ViewWorkbench',
	route: {
		path: '/workbench',
		meta: {
			requiresAuth: true,
			title: 'Workbench'
		}
	},
	// Add any necessary data, methods, or lifecycle hooks here
	components: {
		BaseButton,
		GoogleMap,
		Marker,
		WaitDialog
	},
	setup() {
		const giaResult = ref(null);
		const text1 = ref('');
		const text2 = ref('');
		const center = ref({ lat: 47.2031139, lng: -121.9919582 });
		const mapVisible = ref(false);
		const isWaiting = ref(false);
		const drawer = ref(true);
		const rail = ref(true);
		const resultsInput = ref < ResultsInput[] > ([]);
		const testMap = ref(null);
		const policyEvaluation = ref("Acceptable");
		const goodPolicyEvaluation = ref(false);
		const plrReportUrlDefault = process.env.VUE_APP_PLTR_REPORT_URL;
		const plrReportUrlPolicy = ref(plrReportUrlDefault);
		const electronicWorksheetURLValue = ref(electronicWorksheetURL);

		const fetchPropertyInfo = async (lat: string, long: string) => {
			const propertyInfo = await getPropertyInfo(lat, long);
			giaResult.value = propertyInfo;
		};

		const formatCurrency = (value: number): string => {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
			}).format(value);
		};

		const testPolicy = async (policyQuery: string) => {
			isWaiting.value = true;
			try {
				const res = await CommercialDataService.importPolicy(policyQuery);
				console.log('Policy imported:', plrReportUrlDefault);
				//Add logic to append URL if Policy number is good
				let appendedPolicyQuery = policyQuery.slice(0, 10);
				plrReportUrlPolicy.value = `${plrReportUrlDefault}?PolicyNumber1=${appendedPolicyQuery}`;
				goodPolicyEvaluation.value = true;
				resultsInput.value = res;	
				isWaiting.value = false;
				mapVisible.value = true;
			} catch (error) {
				policyEvaluation.value = "Unacceptable";
				console.error('Error importing policy:', error);
			}
		};

		const getLocation = async (address: string) => {
			isWaiting.value = true;
			try {
				const res = await CommercialDataService.addLocation(address);
				goodPolicyEvaluation.value = true;
				resultsInput.value = res;
				isWaiting.value = false;
				mapVisible.value = true;
			} catch (error) {
				policyEvaluation.value = "Unacceptable";
				console.error('Error getting location:', error);
			}
		};

		const sendDocument = async () => {
			console.log('Exporting to OnBase');
			try {
				await CommercialDataService.exportToDocument();
			} catch (error) {
				console.error('Error exporting to OnBase:', error);
			}
		};

		const removeLocation = (index: number) => {
			resultsInput.value.splice(index, 1);
		};

		const initAutocomplete = () => {
      const input = document.getElementById('addressInput') as HTMLInputElement;
      if (input) {
			/* eslint-disable */
        const autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            text2.value = place.formatted_address || '';
            console.log('Selected place:', place);
          }
        });
      }
    };

		onMounted(() => {
		initAutocomplete();
		});

		return {
			giaResult,
			text1,
			text2,
			center,
			mapVisible,
			isWaiting,
			drawer,
			rail,
			resultsInput,
			testMap,
			fetchPropertyInfo,
			testPolicy,
			getLocation,
			sendDocument,
			formatCurrency,
			policyEvaluation,
			goodPolicyEvaluation,
			initAutocomplete,
			removeLocation,
			plrReportUrlPolicy,
			electronicWorksheetURLValue
		};
	},
}
</script>

<style scoped>
/* Add your custom styles here */
.search {
	background-color: #4d7e5a;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.background {
	background-image: url('../assets/img/moe-city.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.spacer {
	height: 40px;
	background-color: #4d7e5a;
}

.footer {
	background-color: #245e36;
	color: white;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	text-align: center !important;

}

#results-box {
}

#billingAccepted {
	/* border-bottom: 5px solid rgba(214, 124, 59, .5);
	border-top: 5px solid rgba(214, 124, 59, .5); */
	border-bottom: 5px solid rgba(36, 94, 54, .5);
	border-top: 5px solid rgba(36, 94, 54, .5);
	font-weight: 600 !important;
}

#search-box {
	background-color: #245e36;
	color: white;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
}

#title-row {
	/* background-color: #4d7e5a; */
	background-color: #245e36;
	color: white;
	font-weight: bold;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}

#title-row th {
	font-weight: bold;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}

#sub-content {
	/* background-color: #f5f5f5; */
	background-color: #4d7e5a;
	color: white;
}

#sub-content td {
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}

@media (max-width: 600px) {
	.xs-mt-8 {
		margin-top: 8px !important;
	}

	.xs-mb-8 {
		margin-bottom: 8px !important;
	}
}
</style>
