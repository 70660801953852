<template>
	<WaitDialog :isActive="isPushing" title="Staring push..." />

	<v-dialog v-model="dialog" max-width="400" persistent>
		<template v-slot:activator="{ props: activatorProps }">
			<v-btn v-bind="activatorProps" class="moe-button">
				Push Manuals
			</v-btn>
		</template>

		<v-card prepend-icon="mdi-map-marker"
			text="Are you ready to push the commercial manual pages?"
			title="Ready to push?">
			<template v-slot:actions>
				<v-spacer></v-spacer>

				<base-button @click="dialog = false" text="Nevermind" />

				<base-button @click="pushManuals" text="Let's go!" />
			</template>
		</v-card>
	</v-dialog>
</template>
 
<script lang="ts">
import { ref, onMounted, inject } from 'vue';
import { AuthService } from '@/contracts/AuthService';
import { AccountInfo } from '@azure/msal-browser';
import WaitDialog from '@/components/WaitDialog.vue';
import { Tell } from '@/services/Tell';
import { ApplicationInsights, SeverityLevel, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import BaseButton from '@/components/BaseButton.vue';

export default {
	//name doesn't match the file name, but we're doing it like this for uri simplicity on Entra config
	//and for clarity on the vue app
	name: 'PushManuals',
	route: {
		path: '/push-manuals',
		meta: {
			requiresAuth: true,
			title: 'Push Commercial Manuals'
		}
	},
	components: {
		WaitDialog,
		BaseButton
	},
	props: {
		//these properties are exposed to consumers of this component
	},
	setup() {
		const tell = inject('tell') as Tell;
		const telemetry = inject('telemetry') as ApplicationInsights;

		const auth = inject<AuthService>('auth') as AuthService;
		const who = ref<string>('');
		const isPushing = ref<boolean>(false);
		const dialog = ref<boolean>(false);

		onMounted(async () => {
			if(auth)
			{
				await auth.getActiveAccount().then((account: AccountInfo) => {
					who.value = account.name;
				});
			}
		});

		return {
			who,
			isPushing,
			dialog,
			pushManuals: async () => {
				isPushing.value = true;
				dialog.value = false;

				// console.log(JSON.stringify({
				// 	who: who.value
				// }));

				fetch('https://prod-12.westus2.logic.azure.com:443/workflows/aa04d945861f49b98b6946c4a32cb342/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=iTglyqrehdOP4kQJDG15oTmmgD-O1-_aV0Vd-sKPXFo', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						who: who.value
					}),
				}).then((response) => {
					isPushing.value = false;

					if(response.ok)
					{
						tell.success('Manuals push process has been started.');
					}
					else
					{
						telemetry.trackException({ exception: new Error('Failed to start the manuals push process.'), severityLevel: SeverityLevel.Error } as IExceptionTelemetry);
						tell.error('Failed to start the manuals push process.');
					}

					//track that this event happened
					telemetry.trackEvent({ name: `PushManuals.${ response.ok ? "Started" : "Failed" }`, properties: { who: who.value } });
				});
			}
		}
	}
}
</script>
<style scoped></style>