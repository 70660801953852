import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"margin-right":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("todo: put this into a 'header' component"),
    _createVNode(_component_v_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_app_bar, null, {
              prepend: _withCtx(() => [
                _createElementVNode("img", {
                  class: "logo",
                  src: require('./assets/img/green_E.svg')
                }, null, 8 /* PROPS */, _hoisted_1)
              ]),
              append: _withCtx(() => [
                _createCommentVNode(" <v-btn icon=\"mdi-magnify\"></v-btn>\n\t\t\t\t\t<v-badge color=\"error\" content=\"3\" >\n\t\t\t\t\t\t<v-icon icon=\"mdi-bell\" color=\"primary\" ></v-icon>\n\t\t\t\t\t</v-badge>\n\t\t\t\t\t<v-btn icon=\"mdi-account-circle\" color=\"secondary\"></v-btn> "),
                _createElementVNode("div", _hoisted_2, _toDisplayString($setup.authStore.getName), 1 /* TEXT */),
                _createVNode(_component_base_button, {
                  onClick: $setup.logout,
                  text: "Log Out"
                }, null, 8 /* PROPS */, ["onClick"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_app_bar_title, { class: "text-start" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.routeTitle), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_container, { style: {"margin-top":"30px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}